<template>
  <view-item title="保单">
    <template #operation>
      <en-button type="primary" @click="operation.add.click">创建单据</en-button>
      <button-ajax v-if="form.data.id" :method="operation.save.click" :disabled="form.disabled">保存</button-ajax>
      <button-ajax v-if="form.data.id && form.data.status?.code === 'P'" :method="operation.settle.click">提交结算</button-ajax>
      <button-ajax v-if="form.data.id && form.data.status?.code === 'S'" :method="operation.reverseaudit.click">退回</button-ajax>
      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="detail">保单信息</en-dropdown-item>
          <en-dropdown-item command="customer">客户档案</en-dropdown-item>
          <en-dropdown-item command="vehicle">车辆档案</en-dropdown-item>
          <en-dropdown-item command="logs">单据历史</en-dropdown-item>
          <en-dropdown-item v-if="form.data.status?.code === 'P'" command="delete">删除</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        :ajax="{ action: 'GET /enocloud/policy/query' }"
        :props="{ start: 'preparedStartDate', end: 'preparedEndDate' }"
        @expand-click="dialog.visible = true"
        @row-click="manifest.row.click"
      >
        <en-table-column label="单号" prop="serialNo"></en-table-column>
        <en-table-column label="状态" prop="status.message"></en-table-column>
        <template #form="{ data }">
          <en-form-item label="单号">
            <en-input v-model="data.serialNo"></en-input>
          </en-form-item>
          <en-form-item label="商险保险公司">
            <select-maintain
              v-model="data.commercialInsuranceCompanyId"
              :ajax="{
                action: 'GET /enocloud/common/customer',
                params: (params, value) => (params.payload = { name: value, type: 'I' })
              }"
              :props="{ label: 'name', value: 'id' }"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="交险承保公司">
            <select-maintain
              v-model="data.compulsoryInsuranceCompanyId"
              :ajax="{
                action: 'GET /enocloud/common/customer',
                params: (params, value) => (params.payload = { name: value, type: 'I' })
              }"
              :props="{ label: 'name', value: 'id' }"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="车牌">
            <en-input v-model="data.plateNo"></en-input>
          </en-form-item>
          <en-form-item label="状态">
          <select-maintain
            v-model="data.statusCode"
            :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['POLSTAT']) }"
            :props="{ label: 'message', value: 'code' }"
            class="w-full"
          ></select-maintain>
        </en-form-item>
          <en-form-item label="车主">
            <en-input v-model="data.customerName"></en-input>
          </en-form-item>
          <en-form-item label="门店">
            <select-maintain
              v-model="data.branchIds"
              :ajax="{
                action: 'GET /enocloud/common/branch',
                params: (params) => {
                  params.payload = { pagingEnabled: false }
                }
              }"
              :props="{ label: 'shortName', value: 'id' }"
              clearable
              multiple
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="销售人员">
            <en-input v-model="data.salesman"></en-input>
          </en-form-item>
          <en-form-item label="车辆登记日期">
            <en-date-picker
              :start="data.vehicle?.recordDateStart"
              :end="data.vehicle?.recordDateEnd"
              type="daterange"
              @change="(value: [string, string]) => (data.vehicle = { recordDateStart: value[0], recordDateEnd: value[1] })"
            ></en-date-picker>
          </en-form-item>
          <en-form-item label="交险起止时间">
            <en-date-picker
              v-model:start="data.compulsoryInsuranceStartDate"
              v-model:end="data.compulsoryInsuranceEndDate"
              type="daterange"
            ></en-date-picker>
          </en-form-item>
          <en-form-item label="商险起止时间">
            <en-date-picker
              v-model:start="data.commercialInsuranceStartDate"
              v-model:end="data.commercialInsuranceEndDate"
              type="daterange"
            ></en-date-picker>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
        <en-collapse>
          <en-collapse-item>
            <template #title>
              <div class="flex items-center gap-6">
                <span>{{ form.data.vehicle?.plateNo }}</span>
                <span>{{ form.data.customer?.name }}/{{ form.data.customer?.cellphone }}</span>
                <span>单号：{{ form.data.serialNo }}</span>
                <en-tag>{{ form.data.status?.message }}</en-tag>
                <span>{{ form.data.vehicle?.vehicleSpec.join('/') }}</span>
              </div>
            </template>
            <div class="grid grid-cols-3">
              <span>投保人：{{ form.data.holderName }}</span>
              <span>投保人电话：{{ form.data.holderPhone }}</span>
              <span>投保身份证：{{ form.data.holderIdentity }}</span>
              <span>被保人： {{ form.data.recognizeeName }}</span>
              <span>被保人电话： {{ form.data.recognizeePhone }}</span>
              <span>投保身份证： {{ form.data.recognizeeIdentity }}</span>
              <span>交险保险公司： {{ form.data.compulsoryInsuranceCompany?.name }}</span>
              <span>交险单号： {{ form.data.compulsoryInsuranceSerialNo }}</span>
              <span>交险投保日期： {{ formatDate(form.data.compulsoryInsuranceInsureDate) }}</span>
              <span>
                交险起止时间： {{ formatDate(form.data.compulsoryInsuranceStartDate) }}-{{ formatDate(form.data.compulsoryInsuranceEndDate) }}
              </span>
              <span>商险单号： {{ form.data.commercialInsuranceSerialNo }}</span>
              <span>商险保险公司： {{ form.data.commercialInsuranceCompany?.name }}</span>
              <span>商险投保日期： {{ formatDate(form.data.commercialInsuranceInsureDate) }}</span>
              <span>
                商险起止时间： {{ formatDate(form.data.commercialInsuranceStartDate) }}-{{ formatDate(form.data.commercialInsuranceEndDate) }}
              </span>
              <span>初次登记时间： {{ formatDate(form.data.recordDate) }}</span>
              <span>销售人员： {{ form.data.salesman?.name }}</span>
              <span>制单人： {{ form.data.preparedBy?.name }}</span>
              <span>单号： {{ form.data.serialNo }}</span>
              <span>保单照片： {{ form.data.imgUrls?.length }}</span>
            </div>
          </en-collapse-item>
        </en-collapse>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-tabs v-model="tabs.active">
              <en-tab-pane
                label="保险险种"
                name="insurance"
                :badge="form.data.insurances?.length"
                :style="{ height: height - 55 + 'px', overflow: 'auto' }"
                class="gap-6"
              >
                <div class="flex gap-4">
                  <select-maintain
                    :ajax="{
                      action: 'GET /enocloud/common/insurance',
                      params: (params, value) => (params.payload = { name: value })
                    }"
                    :props="{
                      label: 'name',
                      value: '',
                      disabled: (option: EnocloudCommonDefinitions['MaintenanceDto']) =>
                        Boolean(form.data.insurances?.find((item) => item.insurance?.id === option.id))
                    }"
                    value-key="id"
                    placeholder="请选择险种项目"
                    remote
                    class="w-110"
                    @change="form.maintenances.operation.add.change"
                  ></select-maintain>
                </div>

                <flex-box>
                  <template #default="{ height }">
                    <table-dynamic code="PLCDF" :data="form.data.insurances" :height="height">
                      <template #OPERATION="{ $index }: { $index: number }">
                        <en-button :disabled="form.disabled" type="primary" link @click="operation.delete.click($index)"> 删除 </en-button>
                      </template>
                      <template #ORIGINAL_PRICE="{ row }: { row: EnocloudServiceDefinitions['PolicyInsuranceDto'] }">
                        <en-input v-model="row.price" :disabled="form.disabled"></en-input>
                      </template>
                      <template #COVERAGE="{ row }: { row: EnocloudServiceDefinitions['PolicyInsuranceDto'] }">
                        <en-input v-model="row.coverage" :disabled="form.disabled"></en-input>
                      </template>
                      <template #DISCOUNT_RATE="{ row }: { row: EnocloudServiceDefinitions['PolicyInsuranceDto'] }">
                        <en-input v-model="row.discountRate" :disabled="form.disabled"></en-input>
                      </template>
                      <template #REBATE_RATE="{ row }: { row: EnocloudServiceDefinitions['PolicyInsuranceDto'] }">
                        <en-input-rate v-model="row.rebate" :disabled="form.disabled"></en-input-rate>
                      </template>
                      <template #REBATE_AMOUNT="{ row }: { row: EnocloudServiceDefinitions['PolicyInsuranceDto'] }">
                        <en-input v-model="row.rebateAmount" :disabled="form.disabled"></en-input>
                      </template>
                      <template #INSURANCE_SERIAL_NO="{ row }: { row: EnocloudServiceDefinitions['PolicyInsuranceDto'] }">
                        <select-maintain
                          v-model="row.insurance"
                          :ajax="{
                            action: 'GET /enocloud/common/insurance',
                            params: (params, value) => (params.payload = { name: value })
                          }"
                          remote
                          :props="{ label: 'serialNo', value: '' }"
                          :disabled="form.disabled"
                          value-key="code"
                        ></select-maintain>
                      </template>

                      <template #AMOUNT="{ row }: { row: EnocloudServiceDefinitions['PolicyInsuranceDto'] }">
                        {{ formatMoney(row.amount) }}
                      </template>
                    </table-dynamic>
                  </template>
                </flex-box>
              </en-tab-pane>
            </en-tabs>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <policy-detail v-model="detail.visible" :data="form.data" @confirm="detail.confirm"></policy-detail>

  <policy-dialog v-model="dialog.visible"></policy-dialog>
  <vehicle-record v-model="vehicleRecord.visible" :data="form.data.vehicle" @confirm="form.get"></vehicle-record>
  <customer-record
    v-model="customerRecord.visible"
    :customer-data="form.data.customer"
    :vehicle-data="form.data.vehicle"
    @confirm="form.get"
  ></customer-record>
  <workorder-logs v-model="logs.visible" :data="form.data"></workorder-logs>
</template>

<script lang="ts">
import PolicyDetail from '@service/components/policy-detail.vue'
import PolicyDialog from '@service/components/policy-dialog.vue'
import { VehicleRecord, CustomerRecord } from '@enocloud/business'
import WorkorderLogs from '@service/components/workorder-logs.vue'

export default factory({
  components: { PolicyDetail, PolicyDialog, VehicleRecord, CustomerRecord, WorkorderLogs },

  config: {
    children: {
      operation: {
        add: {
          click() {
            this.form.init()
            this.detail.visible = true
          }
        },
        save: {
          async click() {
            await this.form.submit()
            return this.form.get()
          }
        },
        settle: {
          async click() {
            await this.form.settle({ paths: [this.form.data.id] })
            return this.form.get()
          }
        },
        reverseaudit: {
          async click() {
            await this.form.reverseaudit({ paths: [this.form.data.id] })
            return this.form.get()
          }
        },
        delete: {
          async click(index: number) {
            await this.form.data.insurances.splice(index, 1)
          }
        },
        option: {
          async command(option: string) {
            switch (option) {
              case 'detail':
                this.detail.visible = true
                break
              case 'delete':
                await this.form.delete()
                this.form.init()
                break
              case 'vehicle':
                this.vehicleRecord.visible = true
                break
              case 'customer':
                this.customerRecord.visible = true
                break
              case 'logs':
                this.logs.visible = true
                break
            }
          }
        }
      },
      manifest: {
        row: {
          click(row: EnocloudServiceDefinitions['ServiceReservationQueryDto']) {
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        }
      },
      tabs: {
        active: 'insurance'
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/policy/:policyId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'PUT /enocloud/policy',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          delete: {
            action: 'DELETE /enocloud/policy/:policyId',
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          settle: {
            action: 'POST /enocloud/policy/:policyId/settle',
            loading: true,
            params(params) {
              params.payload = { freight: 0, otherCharge: 0 }
            }
          },
          reverseaudit: {
            action: 'PUT /enocloud/policy/:policyId/reverseaudit'
          }
        },
        computed: {
          disabled() {
            return this.form.data.status?.code === 'S'
          }
        },
        children: {
          maintenances: {
            operation: {
              add: {
                change(value: EnocloudServiceDefinitions['MaintenanceDto']) {
                  this.form.data.insurances.push({ insurance: value })
                }
              }
            }
          }
        }
      },
      dialog: {
        visible: false
      },
      detail: {
        visible: false,
        confirm(id: number | undefined) {
          this.form.data.id ??= id
          this.form.get()
        }
      },
      vehicleRecord: {
        visible: false,
        confirm(id: number | undefined) {
          this.form.data.id ??= id
          this.form.get()
        }
      },
      customerRecord: {
        visible: false,
        confirm(id: number | undefined) {
          this.form.data.id ??= id
          this.form.get()
        }
      },
      logs: {
        visible: false
      }
    }
  }
})
</script>
